@import "../../globals/colours";


.page {
    background-color: $pageBackground;

}

.pageTitle {
    font-size: 1.8em;
    padding-bottom: 1.5em;
}
