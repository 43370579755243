@import "../../globals/colours";


.page {
    background-color: $pageBackground;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 1em;
}

.pageTitle {
    font-size: 1.8em;
    padding-bottom: 1.5em;
    font-weight: bold;
}