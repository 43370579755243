@import "../../globals/colours";
@import "../../globals/fonts";
@import "../../globals/styles";

.tpContainer {
  background-color: $headerBackground; // header and footer the same
  margin-top: 1em;
  box-shadow: 0 -4px 6px -2px $tpShadowColour;
}

.cookieBox {
  padding-right: 0.3em;
  padding-left: 0.3em;
}

// the cookie monster himself
.cookie {
  width: auto;
  height: 8em;
  // padding: 0.3em;
}

.noCookiesBox {
}

.footerTitle {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.copyright {
  text-align: center;
  font-size: 0.8em;
  font-style: italic;
}
