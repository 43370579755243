@import "../../globals/colours";


@keyframes flashy {
    0% { 
        transform: scale(1);
        opacity: 0.75;
    }
    50% { 
        transform: scale(1.03);
        opacity: 1;
    }
    100% { 
        transform: scale(1);
        opacity: 0.75;
    }
}
.page {
    background-color: $pageBackground;
    padding-bottom: 0.5em;

}

.pageTitle {
    font-size: 1.8em;
    padding-bottom: 1.5em;
    font-weight: bold;
}

.warning {
    font-size: 2em;
    font-weight: bold;
    color: red;
    animation-name: flashy;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    display: flex;
    justify-content: center;

    padding-bottom: 1em;
}

.warningText {
    color: red;
}

.emergencyTitle {

    font-size: 1.2em;
    font-weight: bold;
    animation-name: flashy;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    padding-bottom: 0.5em;
}
