/*
  global styles for tpcrisis
*/
@import "./colours";

// media queries
$windowXS:  576px ; // x small (less than)
$windowMobile:  576px ; // small (greater than or equal)
$windowTablet:  768px ; // medium
$windowDesktop:  992px ; // large
$windowXL:  1200px ; // x large


// when used on a tag, turns it into a flexbox and centers content vertically and horizontally
.tpFlexCenterContents {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Global styles for articles */
///////////////////////////////////////////////////////////////////////////////////////////////////////////

// image styling - could add a frame or something like that
.artImage {
  margin: 0.2em 2em 0.6em 0.6em;
}

// image with a title
// use as
// <div class="imageWithTitle">
//   <img ...>
//   <span>The title of the image</span>
// </div>
// title MUST be enclosed in a span

.artImageWithTitle {
  display: inline-grid;
  grid-template-columns: min-content;
  grid-template-rows: auto auto;
  padding-bottom: 0.6em;

    & img {
      margin: 0.25em 2em 0.3em 0.6em;
      
      grid-row: 1 / 2;
    }
    & span {
      grid-row-start: 2;
    }
}

// Float Left
// responsive using media query
// to use with bootstrap clear fix
// <div class="clearfix">
//   <div class=artFloatLeft>
//     <div> something else </div>
//   </div>
// </div> 
.artFloatLeft { // mobile first
  float: none;
}

@media only screen and (min-width: $windowMobile) {
  .artFloatLeft {
    float: left;
  }
}

// copied from TpLink, not very efficient as repeating myself
// To do use a sass mixin or something to dry this out
.artLink {
  border-radius: 0.2em;
  font-size: 1.1em; 
  color: $tpTextLinkColour;
  display: inline-block; // otherwise padding doesn't pad long lines
  padding-left: 0.3em;
  padding-right: 0.3em;
  padding-bottom: 0.2em;
  padding-top: 0.2em;

  &:link {
    color: $tpTextLinkColour;
    
  }

  &:visited {
    color: $tpVisitedColour;
  }

  &:hover {
    color: $tpLinkHoverColour;
    background-color: $tpLinkHoverBackground;
    text-decoration: none;
  }

  // ToDo active - animation for active similar to Material Design
  &:active {
    color: $tpLinkActiveColour;
    background-color: $tpLinkActiveBackground;
  }

} 

.artReferences {
  margin-left: 1em;
  margin-bottom: 0.6rem;

  & a {
    display: block; // don't have to add <br /> in html
  }
}

// End of article styles /////////////////////////////////////////////////////////////////////////////////

// This is to replace the bootstrap style
.my-btn-primary {
  color: white;
  background-color: $tpPrimary;
  border-color: $tpPrimary;
  outline: none;

  &:hover {
    color: white;
    background-color: $tpPrimary-d2;
    border-color: $tpPrimary-d2;
    box-shadow:
      0 0 4px 1px $tpShadowColour,
      0.25em 0.25em 3px $tpShadowColour;
  }

  &:active {
    color: red;
    background-color: $tpPrimary-d2;
    border-color: $tpPrimary-l3;
    box-shadow:
      0 0 4px 1px $tpShadowColour,
      0.25em 0.25em 3px $tpShadowColour;
  }

  // same as main, need it to  overwrite bootstrap. Note focus would overwrite hover, so have to have explicit no hover
  &:focus:not(:hover) {
    color: white;
    background-color: $tpPrimary;
    border-color: $tpPrimary;
    box-shadow: none;
  }
}
