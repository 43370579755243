@import "../../globals/colours";
@import "../../globals/fonts";
@import "../../globals/styles";


// use variables for animation duration - easier to change
$flushTime: 2s;
$jokeHeight: 20em;
$jokeOffset: -16em ;

// ToDo everything should be centered better

// The joke stuff needs to be a fixed height so different length jokes can scroll down
// ToDo 
.jokeBox {
  background-color: $pageBackground;
  padding-top: 1em;
}

@keyframes handleFlushAnimation {
  0%   {transform: rotate(0);}
  50%  {transform: rotate(-45deg);}
  100% {transform: rotate(0);}
}

// throw away toilet paper, shrink, rotate, and move off screen
@keyframes tpDispose {
  0% {transform: scale(1) rotate(0) translate(0,0) ; opacity: 1;}
  10% {transform: scale(1.05) rotate(0) translate(0,0) ; opacity: 1;}
  30% {transform: scale(0.4) rotate(20deg) translate(0,0) ; opacity: 1;}

  98% {transform: scale(0.1) rotate(270deg) translate(0,300px) ; opacity: 0.7;}
  100% {transform: scale(0) rotate(270deg) translate(0,300px) ; opacity: 0;}
}

// unroll a new joke

@keyframes unroll {
  0% {transform: translate(0,$jokeOffset); opacity: 1;}
  100% {transform: translate(0,0); opacity: 1}
}

.title {
  font-family: $titleFont, serif;
  font-weight: bold;
  font-size: 2em;
  letter-spacing: 0.08rem;
  margin-bottom: 0.5em;
}

.flusherBox {
  display: flex;
  justify-content: center;
}
// ToDo fix tHandle.png and use a different width (probably 80%)
// ToDo center tHandle.png
.tHandle {
  height: auto;
  width: 60%;
  transform-origin: 75% 50%;

  &:hover {
    transform: scale(1.08);
  }
  
  
}

.imageBox {
  display:flex;
  justify-content: center;
  padding-bottom: 2em;
}
// toDo change pointer to arrow
.flushMe {
  display: flex;
  justify-content: center;
  font-size: 2.5em;
  font-weight: bold;
  letter-spacing: 0.08rem;
  cursor: context-menu;
  outline: none;

  &:hover {
    background-color: change-color( $tpLinkHoverBackground, $alpha:0.4 );
    border-radius: 20px;
  }

  &:active {
    background-color: rgba(0,0,0,0)
  }
}

.tHandleFlush {
  animation-name: handleFlushAnimation;
  animation-duration: $flushTime ;
  animation-iteration-count: 1;
  animation-timing-function: linear;

}

.tRoll {
  width: auto;
  height: 4em;
  margin-top: 1.5em;
}

// ToDo - tiled background, like a toilet ....
.upperBox {
  background-image: url("../../images/tiles.jpg");
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-bottom: 1.5em;


}

// The joke container is sized to be big enough for any of the jokes
.paperBox {
  height: $jokeHeight;
}

// This container hides overflow, so that joke can scroll in
// ToDo. On mobile, height should be specific
.tpJokeBox {
  overflow: hidden;
  height: $jokeHeight;
}

.tpJoke {
  padding: 0.75em 1em 0.75em 1em;
  width: 60%;
  border-style: solid;
  border-radius: 5px;
  border-color: $tpJokesBorderColour;
  background-color: $tpJokesBackgroundColour ;
  background-image: url("../../images/tpTexture3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 
    4px 4px 5px $tpShadowColour;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  margin-left: auto;
  margin-right: auto;

  color: $tpTextColour-d2;

}

.jokeLine {
  padding: 0.25em 0 0.25em 0;
}
// Joke animation
// Unroll
// Throw away
// - initially, just shrink to nothing, goes down off page
// Later, crumple and throw into toilet bowl (may be too hard)

.tpFlush {
  animation-name: tpDispose;
  animation-duration: $flushTime ;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.tpUnroll {
  animation-name: unroll;
  animation-duration: $flushTime ;
  animation-iteration-count: 1;
  animation-fill-mode: forward; // maintains properties of last animation frame.
}
