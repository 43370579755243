@import "../../globals/colours";
@import "../../globals/fonts";

// link needs to be easy to touch on mobile device
// space out from others, slightly larger text
// possibly style differently for mobile, background to make target larger ?

.linkStyle a {
  border-radius: 0.2em;
  font-size: 1.1em; 
  color: $tpTextLinkColour;
  display: inline-block; // otherwise padding doesn't pad long lines
  
  padding: 0.3em 0.3em 0.4em 0;

}

.linkStyle {

  // ToDo add box-shadow to hover/visited

  & a:link {
    color: $tpTextLinkColour;
    
  }

  & a:visited {
    color: $tpVisitedColour;
  }

  & a:hover {
    color: $tpLinkHoverColour;
    background-color: $tpLinkHoverBackground;
    text-decoration: none;
  }

  // ToDo active - animation for active similar to Material Design
  & a:active {
    color: $tpLinkActiveColour;
    background-color: $tpLinkActiveBackground;
  }

  
}

// this is tweaked as a covid19 style on home page, needs fixing
.scrollStyle {
  
  border-radius: 0.2em;
  font-size: 1.1em; 
  display: inline-block; // otherwise padding doesn't pad long lines
  
  padding: 0.3em 0.3em 0.4em 0;
  color: $covidTextLink;
  cursor: pointer;
  
  &:hover {
    color: $covidTextLinkHover;
    background-color: $covidLinkBackground;
    text-decoration: none;
  }
  &:active {
    color: $tpLinkActiveColour;
    background-color: $tpLinkActiveBackground;
  }
}

