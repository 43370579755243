@import "../../globals/colours";

.desktopBox {
  position:sticky;
  top:0;
  background-color: $pageBackground;
}

.titleBox {
  background-color: $navMenuBackground;
  font-weight: bold;
  padding: 0.25em 0 0.25em 0.6em;
}

.menuBox {
  background-color: $navMenuBackground;
  padding: 0.25em 0.25em 0.25em 0.6em;
  font-size: 1.1em;
}

.stripey:nth-child(2n) {
  background-color: $navZebra ;
  padding-left: 0.2em;
  padding-right: 0.2em;
  border-radius: 0.2em;
}