


body {
  margin: 0;
  background-color: rgb(80, 80, 80); 

}





