@import "../../globals/colours";

.topBox {
  background-color: $navMenuBackground;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.menuContentBox {
  font-size: 1.2em;
}

// taken from caret
.menuButton {
  &::after {
    // These two used to line up the caret
    vertical-align: 0.15em;
    margin-left: .6em;

    // These set the size of the caret
    border-top: .5em solid;
    border-right: .5em solid transparent;
    border-bottom: 0;
    border-left: .5em solid transparent;
  }
}
.hamburger {
  height: 1em;
  padding-right: 0.5em;
}

.dropdownBox {
  box-shadow: 
  3px 3px 5px 3px $tpShadowColour;
  background-color: $navMenuBackground;
  min-width: 80vw;
  max-width: 85vw;
  white-space: normal;
  padding: 0 0.2em 0 0.4em;

}


.dropItem {
  white-space: normal;
}

.stripey:nth-child(2n) {
  background-color: $navZebra ;
  padding-left: 0.2em;
  padding-right: 0.2em;
  border-radius: 0.2em;
}

