@import "../../globals/colours";


.page {
    background-color: $pageBackground;

}

.indent {
    margin-left: 2em;
}