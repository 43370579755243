@import "../../globals/colours";
@import "../../globals/fonts";

.page {
  background-color: $pageBackground;
}

.linkedInBox {
  margin-top: auto;
  margin-bottom: 1em;
}

// LinkedIn logo to click
.contactLI {
  height: auto;
  width: 80%;
  padding-bottom: 0.1em;
}

.githubLink {
  font-size: 1.2em;
}
.copyBox {
  font-size: 0.85em;
  font-style: italic;
  margin-left: 2em;
  margin-right: 2.25em;

}

// the cookie monster himself
.cookie {
  width: auto;
  height: 6em;
  padding: 0 0.8em 0.8em 0;
  float: left;
}

.clear {
  clear: both;
}



