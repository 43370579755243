/* Toilet paper crisis colours */

/* bootstrap 4 window sizing breakpoints */
$b4XSmall: 576; // less than 
$b4small: 576;  // more than or equal
$b4medium: 768;
$b4large: 992;
$b4XLarge: 1200;

/* base colours */
$tpPrimary: rgb(0, 102, 255);
$tpSecondary: grey;

/* backgrounds */

$headerBackground: #a5a39c;
$headerBackgroundShade: scale-color($headerBackground, $lightness: -70%);
$navBackground: #7c5252;

$pageBackground: scale-color($headerBackground, $saturation: -10%, $lightness: +40%);
$footerBackground: $headerBackground;

$bodyBackground: $pageBackground;
$covidBackground: scale-color($bodyBackground, $hue: 30%, $lightness: +40%);
$covidLinkBackground: scale-color($covidBackground, $lightness: -25%, $hue: -10%);

$navMenuBackground: $covidBackground;
$navZebraTemp: scale-color( $covidBackground, $lightness: -10%, $saturation: -20%, $alpha: 0.12);
$navZebra: change-color($navZebraTemp, $alpha: 0.6);
/* short Jokes on a toilet roll */

$tpJokesBackgroundColour:  #bfbfbf;
$tpJokesShadowColour: #313131; 
$tpJokesBorderColour: scale-color($tpJokesBackgroundColour, $lightness: -25%);

$tpShadowColour: darkslategray;

/* Articles */

$tpArticleBackground: scale-color($pageBackground, $hue: 30%, $lightness: +40%);

/* Darker shades */
$tpPrimary-d1: scale-color($tpPrimary, $lightness: -20%); 
$tpPrimary-d2: scale-color($tpPrimary, $lightness: -40%); 
$tpPrimary-d3: scale-color($tpPrimary, $lightness: -60%); 

$tpSecondary-d1: scale-color($tpSecondary, $lightness: -20%); 
$tpSecondary-d2: scale-color($tpSecondary, $lightness: -40%); 
$tpSecondary-d3: scale-color($tpSecondary, $lightness: -60%); 

/* Lighter shades */
$tpPrimary-l1: scale-color($tpPrimary, $lightness: +15%); 
$tpPrimary-l2: scale-color($tpPrimary, $lightness: +30%); 
$tpPrimary-l3: scale-color($tpPrimary, $lightness: +45%); 

$tpSecondary-l1: scale-color($tpSecondary, $lightness: +15%); 
$tpSecondary-l2: scale-color($tpSecondary, $lightness: +30%); 
$tpSecondary-l3: scale-color($tpSecondary, $lightness: +45%); 

/* text colours */
$titleTextColour: #030733; /* very dark blue */
$tpTextColour: #151627;
$covidText: #471c1c; /* dark red */
$covidTextTitle: scale-color($covidText, $lightness: 30%, $saturation: 80% );
$covidTextLink: #0f4c81; // Pantone colour of the year 2020
$covidTextLinkHover: scale-color($covidTextLink, $lightness: 10%); 

/* Link colours */
$tpTextLinkColour: blue;
$tpLinkHoverColour: scale-color($tpTextLinkColour, $lightness: -20%, $saturation: 10%);
$tpLinkActiveColour: red;
$tpVisitedColour: scale-color($tpTextLinkColour, $lightness: -50%, $saturation: 30%);

$tpLinkHoverBackground: scale-color($bodyBackground, $lightness: -25%, $hue: -10%, $alpha: -30% );
$tpLinkActiveBackground: scale-color($bodyBackground, $lightness: -5%, $hue: -10%, $alpha: -30% );
// Note - Hover and Active should be different, do that can see when button pressed on desktop //

/* Lighter shades */
$titleTextColour-l1: scale-color($titleTextColour, $lightness: +15%);
$titleTextColour-l2: scale-color($titleTextColour, $lightness: +30%);
$titleTextColour-l3: scale-color($titleTextColour, $lightness: +40%);

/* Darker shades */
$tpTextColour-d2: scale-color($tpTextColour, $lightness: -30%);