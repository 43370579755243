@import "../../globals/colours";
@import "../../globals/fonts";

.page {
  background-color: $pageBackground;
}



.covid19 {
  font-size: 1.2em;
  // This font only has a single weight - bold
  font-family: $covidFont, -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif;
  color: $covidText;
  line-height: 1.35em;
  background-color: $covidBackground;
  
  & a {
    color: $covidTextLink;
  }

  & a:hover {
    color: $covidTextLinkHover;
    background-color: $covidLinkBackground;
    text-decoration: none;
  }
}

// inherits all the .covid19 styles
.tLinkStyle {
  
  color: $covidTextLink;
  cursor: pointer;
  
  &:hover {
    color: $covidTextLinkHover;
    background-color: $covidLinkBackground;
    text-decoration: none;
  }
}
// ToDo full styling for covid links (or maybe convert to TpLink)
// add :link :visited :active

.hyperlink {
  color: red;
  padding-bottom: 0.1em;
}

.covidTitle {
  font-size: 1.8em;
  line-height: 1.3;
  color: $covidTextTitle;
}

.gap {
  height: 0.4em;
}

// ToDo consider making the padding (margins) responsive
.covidLinkBox {
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
}
