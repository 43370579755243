@import "../../globals/colours";


.articleBox {
  box-shadow: none;
  padding-bottom: 0.5em;
  margin-bottom: 0.75em;
}
.articleBoxOpen {
  box-shadow: 0 0 8px 1px $tpShadowColour;
}


.topBox {
  background-color: $tpArticleBackground ;
  overflow: hidden;
  margin-bottom: 1em;
  padding-top: 0.5em;
}

.topBoxClosed {
  box-shadow: none;

  &:hover {
    transform: scale(1.02);
    border-radius: 5px;
    
    box-shadow: 
      4px 4px 8px $tpShadowColour,
      0 0 8px -1px $tpShadowColour ;  
  }
}

.articleButtonBox {
  margin: 0.5em auto 0.5em auto;
}
.contentBox {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display:none;
}

.contentBoxOpen {
  display: block;
}

.endBox {
  display:none;
}

.endBoxOpen {
  display: flex;
}
.articleCloseButtonBox {
  margin-bottom: 0.5em;
}