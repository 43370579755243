/* set up body fonts, background, styles */
@import "./globals/_colours";
@import "./globals/_fonts.scss";


body {
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

  // this is the default font
  font-family: $paragraphFont, -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  line-height: 1.2; // adjust this for each of the different fonts. Reset used 1.5


  color: $tpTextColour;
  background-color: $bodyBackground;
  background-image: url("./images/paperBG.jpg");
}
