@import "../../globals/colours";
@import "../../globals/fonts";

@keyframes bouncy {
  0% { transform: rotate(0) translateX(0);}
  20% {transform: rotate(-7deg) translateX(-5px);}
  30% {transform: rotate(-7deg) translateX(-5px);}
  60% {transform: rotate(5deg) translateX(2px);}
  80% {transform: rotate(5deg) translateX(2px);}
  100% { transform: rotate(0) translateX(0);}
}
@keyframes bouncyHover {
  0% { transform: scale(1.1) rotate(0) ;}
  50% {transform: scale(1.1) rotate(180deg) ;}
  100% { transform: scale(1.1) rotate(360deg) translateX(0);}
}
.tpContainer {
  background-color: $headerBackground;
  margin-bottom: 1em;
  box-shadow: 0 4px 6px -2px $tpShadowColour;
}

// .imBox {
//   justify-content:space-around;
// }

.im {
  animation-name: bouncy;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-direction: normal;

  height: 12vw;
  min-height: 120px;
  padding: 0.6em;
  margin-left:auto;
  margin-right: auto;
}

// hover behavior tricky. Can't easily do box-shadow on a png image. Rotate instead of bounce
.im:hover {
  animation-name: bouncyHover;
}

// ToDo animation for active

.imVirus {
  animation-duration: 2s;
}

.imTP {
  animation-duration: 1.75s;
}

.title {
  font-family: $titleFont, serif;
  font-weight: bold;
  font-size: 3em;
  letter-spacing: 0.1em;
  text-align: center;
  margin-top:auto;
  margin-bottom: auto;
  padding: 0.6rem;
  text-decoration: none ;

  &:hover {
    transition: scale 0.25s;
    transform: scale(1.04,1.04);
  }

  & a {
    color: $titleTextColour; // has to be here, otherwise does not get styled
  }

}

